/* Regular styles */
nav {
    margin-top: 1rem;
    background-color: var(--off-black); /* Dark background */
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    height: 6rem;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding: 1rem;
}

nav > ul {
    list-style-type: none;
    display: flex; 
    align-items: center; 
    justify-content: space-between; 
    height: 100%; 
    padding: 0;
    margin: 0;
    transition: transform 0.5s ease-in-out;
}

.nav-links {
    display: flex;
}

.nav-links.open {
    display: block;
}

.nav-li {
    padding: 14px;
    display: flex;
    align-items: center; 
    height: 100%; 
}

.nav-li > a {
    color: var(--white); /* Main link color */
    font-size: 1.3rem;
    font-weight: 600;
    opacity: 0.8; /* Higher opacity for visibility */
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
}

.nav-li > a > .link {
    color: var(--white); /* Main link color */
    font-size: 1.3rem;
    font-weight: 600;
    opacity: 0.8; /* Higher opacity for visibility */
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
}

.nav-li > a > .link:hover {
    color: #a0a0a0; /* Brighter hover color */
}
/* Set hover color for normal menu links */
.nav-li > a:hover {
    color: #a0a0a0; /* Brighter hover color */
}

nav h1 span {
    background: linear-gradient(45deg, #ff4e50, #f9d423);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
}

a {
    text-decoration: none;
}

/* Header Button */
.header-button {
    background-color: #1e2d3d; /* Dark blue background */
    color: var(--white); /* Text color */
    padding: 23px 19px 19px 19px;
    font-size: 1.3rem;
    font-weight: 600;
    border: none;
    transition: 0.3s; /* Smooth transition */
}

.header-button:hover {
    background-color: #c7c7c7; /* Vibrant green on hover */
    color: var(--off-black); /* Dark text for contrast */
    transition: 1s;
}

/* Hamburger Menu Icon */
.menu-icon {
    display: none;
    flex-direction: column;
}

.line {
    width: 25px;
    height: 3px;
    background-color: #afafaf; /* Dark line color */
    margin: 4px;
    transition: all 0.3s ease;
}

.line.open:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
}

.line.open:nth-child(2) {
    opacity: 0;
}

.line.open:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
}

/* Responsive Styles */
@media (max-width: 768px) {
    nav {
        position: relative;
        margin-top: 1rem;
        flex-flow: row;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80%;
        height: 6rem;
        padding: 1rem;
        z-index: 50;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }
    
    .nav-links {
        position: absolute; 
        top: 100%; 
        left: 0;
        width: 100%; 
        height: auto; 
        overflow: hidden;
        background-color: #585555; /* Dark background for dropdown */
        display: none;
        flex-direction: column; 
        align-items: center; 
        justify-content: center; 
        transition: height 0.3s ease;
        padding: 1rem 0; 
    }
    
    .nav-links.open {
        display: flex; /* Show links in open state */
    }

    .nav-li {
        width: 100%; 
        text-align: center; 
        padding: 1rem 0; 
    }

    .nav-li > a {
        display: block; 
        width: 100%; 
        color: var(--white); /* Maintain normal link color */
        opacity: 0.8; /* Consistent opacity */
        transition: color 0.5s ease; /* Transition for hover effect */
    }

    .menu-icon {
        display: flex;
    }

    /* Mobile Header Button */
    .header-button {
        text-align: center; 
        border: none;
    }
}
