.pricing-plans {
  display: flex;
  height: 80vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  background-color: #1f1f1f;
  overflow: hidden;
}

.plan {
  padding: 20px;
  width: 300px;
  height: 50vh;
  text-align: center;
  background-color: #2b2b2b;
  margin: 20px;
  color: var(--light-grey);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.plan:hover {
  transform: translateY(-1rem);
  transition: 1s;
}

.main-skills {
  color: var(--white);
  margin-bottom: 5vh;
  padding: 20px;
  background-color: #535252;
}

.features {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
}

.features li {
  display: flex;
  color: var(--light-grey);
}

/* Mobile responsive styling */
@media (max-width: 768px) {
  .pricing-plans {
    flex-direction: column; /* Stack the plans vertically */
    height: 100vh; /* Allow height to adjust automatically */
    width: 100vw; /* Full width for mobile */
  }

  .plan {
    height: 30vh;
    width: 90%; /* Make the plans take most of the screen width */
    margin: 3px auto; /* Center the plans with reduced margins */
  }
}
