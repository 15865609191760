@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');

:root {
  --grey: #b8b8b8;
  --off-black: #1e1e1e;
  --white: #fff;
  --full-black: #000;
  --light-grey: #d6d6d6;
  --hover-dark: #7c7c7c;
  --accent-color: #ff5722;
  --dark-bg: #181818;
  --light-bg: #2b2b2b;
}

* {
  cursor: none;
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--dark-bg);
  font-family: "Space Grotesk", sans-serif;
  color: var(--light-grey);
  cursor: none;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  display: none;
}

h1, h2, h3 {
  color: var(--white);
  letter-spacing: 2px;
  text-transform: uppercase;
  margin: 0;
  padding: 20px 0;
}

.cursor {
  position: fixed; /* Ensure cursor stays within the viewport */
  top: 0;
  left: 0;
  width: 32px;
  height: 32px;
  background: var(--white);
  border-radius: 50%;
  pointer-events: none;
  z-index: 50000;
  transition: transform 0.1s ease;
  will-change: transform;
}

.cursor-follow {
  transform: scale(1.5);
}

@media screen and (max-width: 992px) {
  .cursor {
    display: none; /* Hide the custom cursor on smaller screens */
  }
}


/* a{
  cursor: none;
}
button{
  cursor: none;
} */
