/* src/css/hero.css */

.hero {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
  color: white; /* Text color */
  text-align: center; /* Center align text */
  position: relative;
  overflow: hidden; /* Prevent content overflow */
  min-height: min-content;
  text-shadow: 5px 5px 10px #ffffffbe, -5px -5px 10px #ffffffbe;
}

#mask-image {
  mask-image: url(../imgs/mask.svg);
  mask-repeat: no-repeat; /* Prevent repeating */
  position: absolute; /* Position it absolutely */
  top: -30%; /* Move down from the top */
  left: 40%; /* Move right from the left */
  right: auto; /* Don't set right */
  bottom: auto; /* Don't set bottom */
  width: 100%;
  height: 100%;
  z-index: 1; /* Set a lower z-index than hero-content */
}


.hero-content {
  max-width: 1000px; /* Limit the max width for larger screens */
  padding: 20px;
  position: relative; /* Create a stacking context */
  z-index: 2; /* Ensure it's above the image */
}

  
  .hero-title {
    font-size: 2.5rem; /* Larger title */
    margin: 0.5rem 0; /* Spacing */
    font-weight: 700; /* Bold */
  }
  
  .hero-subtitle {
    font-size: 1.25rem; /* Subtitle size */
    margin-bottom: 1.5rem; /* Bottom spacing */
    width: 90%;
  }
  
  .button-group {
    display: flex;
    justify-content: center; /* Center buttons */
    gap: 20px; /* Space between buttons */
  }
  
  .btn {
    padding: 12px 24px; /* Button padding */
    font-size: 1rem; /* Button text size */
    background-color: transparent; /* Transparent background */
    border: 2px solid white; /* White border */
    border-radius: 30px; /* Rounded corners */
    color: white; /* Button text color */
    transition: all 0.3s ease; /* Smooth transition */
  }
  

  
  .btn:hover {
    background-color: rgba(0, 0, 0, 0.514); /* Change primary button background on hover */
  }
  
  /* Optional: Add a decorative effect */
  
  /* Add a blinking cursor effect for larger screens */
  @media (min-width: 1000px) {
    .span-hero {
      display: inline-block; /* Ensure the span behaves like a block for width animations */
      overflow: hidden; /* Hide the overflow to create the effect */
      white-space: nowrap; /* Prevent the text from wrapping */
      border-right: 4px solid rgba(255, 255, 255, 0.75); /* Create a cursor effect */
      width: 0; /* Start with no width */
      animation: typing 3s steps(42) forwards; /* Typing animation */
    }
    .span-hero {
      animation: typing 3s steps(42) forwards, blink-caret 0.75s step-end infinite; /* Combine typing and blinking */
    }
  
    @keyframes blink-caret {
      50% {
        border-color: transparent; /* Blink effect for the cursor */
      }
    }
  }
  
  @keyframes typing {
    to {
      width: 100%; /* End with full width */
    }
  }
  @media screen and (max-width: 992px) {
    #mask-image {
      mask-image: url(../imgs/mask.svg);
      mask-repeat: no-repeat; /* Prevent repeating */
      position: absolute; /* Position it absolutely */
      top: -30%; /* Move down from the top */
      left: 0%; /* Move right from the left */
      right: auto; /* Don't set right */
      bottom: auto; /* Don't set bottom */
      z-index: 1; /* Set a lower z-index than hero-content */
      
      object-fit: cover; /* Cover the entire container */
      object-position: 50% 25%; /* You can still adjust this for the image focus */
    }

    .hero {
      height: 60vh;
    }
  }
  