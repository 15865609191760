.project1-root {
    font-family: "Bebas Neue", "Montserrat", "pp-neue-montreal", sans-serif;
    height: 100%;
    background-color: var(--dark-bg);
  }
  
  .project1-root * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .project1-root::-webkit-scrollbar {
    display: none;
  }
  
  .project1-root h1 {
    font-size: 80px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: -0.02em;
  }
  
  .project1-root p {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5;
    width: 50%;
  }
  
  .project1-root img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  #project1-logo {
    position: fixed;
    top: 0;
    right: 0;
    margin: 2em;
    width: 18px;
    height: 18px;
    background: red;
    border-radius: 100%;
    z-index: 10000;
  }
  
  .project1-header {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    width: 100%;
    z-index: 2;
  }
  
  .project1-letters {
    flex: 1;
    display: flex;
    text-transform: uppercase;
  }
  
  .project1-letters > div {
    flex: 1;
    font-size: 12vw;
    font-weight: 700;
    color: #E50914;
    text-align: center;
    letter-spacing: -0.05em;
  }
  
  .project1-website-content {
    position: relative;
    top: 0;
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
  }
  
  .project1-img-holder {
    position: relative;
    top: 0;
    width: 100%;
    height: 100vh;
    clip-path: polygon(37.5% 20%, 62.5% 20%, 62.5% 80%, 37.5% 80%);
    transform: rotate(30deg);
  }
  
  .project1-img-holder img {
    position: relative;
    transform: scale(2);
  }
  
  .project1-content-holder {
    position: relative;
    width: 100%;
    background-color: var(--dark-bg);
    color: white;
  }
  
  .project1-row {
    margin: 2em 1em;
  }